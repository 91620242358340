import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Link from "next/link";
import Router, { useRouter } from "next/router";
import style from "./button-navigation.module.scss";
import { getCookie, getLocal, removeLocal, setLocal } from "../../common/function/storage";
import { handleLogout } from "../../redux-store/actions/auth";
import {
  handleGetAllPlans,
  handleRemoveUploadedFile,
} from "../../redux-store/actions/albums";
import { checkLogOrNot } from "../../common/function/function";
import { emailDashboard } from "../../common/email";
const moreMenu = () => {
  const dispatch = useDispatch();
  const _TOKEN = getLocal("access_token");
  const _ROUTES = getLocal("routers");
  const _ACTIVE = getLocal("active");
  const location = useRouter();
  const _OPEN_MORE_MENU = useSelector((state) => state.common.openMoreMenu);
  const _REMOVE_ALL_FILE = useSelector((e) => e.common.removeAllFile);
  let parseDetails = getCookie("login_user") ? getCookie("login_user") : null;
  const ALREADY_LOGGING = parseDetails;
  const [active, setActive] = useState();
  const CloseMoreMenu = () => {
    dispatch({ type: "OPEN_MORE_MENU", payload: false });
    dispatch({
      type: "SEARCH_VALUE",
      payload: "",
    });
    dispatch({
      type: "ON_CLICK_SEARCH_HISTORY",
      payload: "",
    });
    dispatch({
      type: "[ALBUMS] GET_OPEN_FILTER_RESPONSE",
      payload: null,
    });
    dispatch({
      type: "OPEN_MOBILE_SEARCH",
      payload: false,
    });
    const body = document.querySelector("body");
    body.style.overflow = "auto";
  };

  const commonClick = useCallback((routerName, setLocalName) => {
    Router.push({
      pathname: `${routerName}`,
    });
    if (routerName !== "/store/albums") {
      dispatch({
        type: "ALBUMS_TYPE",
        payload: null,
      });
    }
    removeLocal("active");
    removeLocal("routers");
    setLocal("routers", `${setLocalName}`);
    CloseMoreMenu();
  });
  const logout = async () => {
    if (_REMOVE_ALL_FILE?.length)
      await dispatch(handleRemoveUploadedFile(_REMOVE_ALL_FILE));
    await dispatch(handleLogout(Router));
    dispatch(handleGetAllPlans(_ROUTES));
    dispatch({ type: "[ALBUMS] GET_SUBSCRIPTION", payload: [] });
    dispatch({
      type: "SELECTED_PAYMENT_PLAN",
      payload: "Annually",
    });
    dispatch({
      type: "ALBUMS_TYPE",
      payload: null,
    });
    setLocal("active", `/`);
    CloseMoreMenu();
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = _OPEN_MORE_MENU ? "hidden" : "auto";
  }, [_OPEN_MORE_MENU]);

  useEffect(() => {
    setActive(_ACTIVE);
  }, [_ACTIVE]);

  const openSoundBoard = () => {
    removeLocal("website");
    setLocal("website", "website");
    window.open(process.env.NEXT_PUBLIC_SOUNDBOARD, "_self");
  };

  const planDiv = () => (
    <div
      className={`${style.links_div} cursor-pointer 
        ${active === "pricing" ? style.active : ""}`}
      onClick={() => {
        _TOKEN && checkLogOrNot(_TOKEN);
        removeLocal("active"), setLocal("active", `pricing`);
        CloseMoreMenu();
      }}
    >
      <p>Plan</p>
      <i className="icon-right-arrow-backup-2-svgrepo-com"></i>
    </div>
  );

  const toolDiv = () => (
    <div
      className={`${style.links_div} cursor-pointer 
    ${active === "tool" ? style.active : ""}`}
      onClick={() => {
        _TOKEN && checkLogOrNot(_TOKEN);
        removeLocal("active"), setLocal("active", `tool`);
        CloseMoreMenu();
      }}
    >
      <p>Tool</p>
      <i className="icon-right-arrow-backup-2-svgrepo-com"></i>
    </div>
  );

  const planTokenDiv = () => (
    <div
      className={`${style.links_div} cursor-pointer 
        ${location?.route === "/change-plan" ? style.active : ""}`}
      onClick={() => {
        _TOKEN && Router.push("/change-plan");
        removeLocal("active");
        CloseMoreMenu();
      }}
    >
      <p>Plan</p>
      <i className="icon-right-arrow-backup-2-svgrepo-com"></i>
    </div>
  );

  const commonDashboardClick =(route) => {
    Router.push({
      pathname: route,
    });
    dispatch({
      type: "CART_DROP_DOWN_TOGGLE",
      payload: false,
    });
    commonClick(route, route);
  }
  const commonContactUsClick =() => {
    Router.push({
      pathname: "/support-tickets",
    });
    dispatch({
      type: "CART_DROP_DOWN_TOGGLE",
      payload: false,
    });
    commonClick("/support-tickets", "/support-tickets");
  }

  return (
    <div className={style.more_menu_main_div} style={{ zIndex: "99999" }}>
      <div className={style.more_menu_contain_div}>
        <h2>More</h2>
        <div className={style.more_menu_links_div}>
          <div
            className={`${style.links_div} cursor-pointer Soundboard-link-div`}
            onClick={() => openSoundBoard()}
          >
            <div>
              <p>Soundboard</p>
            </div>
            <div>
              <i className="icon-Soundboard-icon Soundboard-music-icon"></i>
              <i className="icon-right-arrow-backup-2-svgrepo-com"></i>
            </div>
          </div>
          {_TOKEN ? planTokenDiv() : <Link href="/#pricing">{planDiv()}</Link>}

          {!_TOKEN ? <Link href="/#tool">{toolDiv()}</Link> : null}

          {/* {!_TOKEN ? toolDiv() : <Link href="/#tool">{toolDiv()}</Link>} */}

          {_TOKEN && (
            <div
              // className={style.links_div}
              className={`${style.links_div} cursor-pointer 
              ${location?.route === "/store/library" ? style.active : ""}`}
              onClick={() => {
                _TOKEN && commonClick("/store/library", "/store/library");
              }}
            >
              <p>My Library</p>
              <i className="icon-right-arrow-backup-2-svgrepo-com"></i>
            </div>
          )}
          {/* <div
            // className={style.links_div}
            className={`${style.links_div} cursor-pointer 
              ${location?.route === "/store/albums" ? style.active : ""}`}
            onClick={() => {
              commonClick("/store/albums", "/store/albums");
              dispatch({ type: "OPEN_ALBUMS_DETAIL", payload: null });
            }}
          >
            <p>Albums</p>
            <i className="icon-chevron-rigth-arrow"></i>
          </div> */}
          {/* {!_TOKEN && (
              <React.Fragment>
                <div
                  className={style.links_div}
                  onClick={() => {
                    commonClick("/auth/signUp", "/auth/signUp");
                  }}
                >
                  <p>Sign Up</p>
                  <i className="icon-chevron-rigth-arrow"></i>
                </div>
              </React.Fragment>
            )} */}
          {_TOKEN && (
            <React.Fragment>
            <div
              className={`${style.links_div} cursor-pointer 
            ${location?.route === "/account" ? style.active : ""}`}
              onClick={() => {
                _TOKEN && commonClick("/account", "/account");
              }}
            >
              <p>My Account</p>
              <i className="icon-right-arrow-backup-2-svgrepo-com"></i>
            </div>

              {ALREADY_LOGGING?.rolename === 'admin' ? (
                <React.Fragment>
                  <div
                    className={`${style.links_div} cursor-pointer 
                    ${location?.route === "/dashboard" ? style.active : ""}`}
                    onClick={() => {
                      _TOKEN && commonDashboardClick("/dashboard");;
                    }}
                  >
                    <p>Dashboard</p>
                    <i className="icon-right-arrow-backup-2-svgrepo-com"></i>
                  </div>
                  <div
                    className={`${style.links_div} cursor-pointer 
                    ${location?.route === "/contact-us-list" ? style.active : ""}`}
                    onClick={() => {
                      _TOKEN && commonContactUsClick();
                    }}
                  >
                    <p>Support Tickets</p>
                    <i className="icon-right-arrow-backup-2-svgrepo-com"></i>
                  </div>
                  {/* {ALREADY_LOGGING?.artist_id ? */}
                    {/* <div
                      className={`${style.links_div} cursor-pointer 
                      ${location?.route === "/artist-dashboard" ? style.active : ""}`}
                      onClick={() => {
                        _TOKEN && commonDashboardClick("/artist-dashboard");
                      }}
                    >
                      <p>Artist Dashboard</p>
                      <i className="icon-right-arrow-backup-2-svgrepo-com"></i>
                    </div> */}
                  {/* : null}  */}
                </React.Fragment>
              ) : null}

              {ALREADY_LOGGING?.rolename === "admin" || ALREADY_LOGGING?.partner_id  ? (
                <div
                  className={`${style.links_div} cursor-pointer 
                    ${location?.route === "/partner-Artist " ? style.active : ""}`}
                  onClick={() => {
                    _TOKEN && commonDashboardClick("/partner-dashboard");
                  }}
                >
                  <p>Partner Dashboard</p>
                  <i className="icon-right-arrow-backup-2-svgrepo-com"></i>
                </div>
              ):null}
              
            </React.Fragment>
          )}
          <div
            className={`${style.links_div} cursor-pointer 
             ${location.pathname === "/contact" ? style.active : ""}`}
            onClick={() => {
              commonClick("/contact", "/contact");
            }}
          >
            <p>Contact</p>
            <i className="icon-right-arrow-backup-2-svgrepo-com"></i>
          </div>
          <div
            className={`${style.links_div} cursor-pointer 
             ${location.pathname === "/about-us" ? style.active : ""}`}
            onClick={() => {
              commonClick("/about-us", "/about-us");
            }}
          >
            <p>About</p>
            <i className="icon-right-arrow-backup-2-svgrepo-com"></i>
          </div>
          {/* {_TOKEN && (
            <div className={style.links_div} onClick={() => logout()}>
              <p>Logout</p>
              <i className="icon-chevron-rigth-arrow"></i>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default moreMenu;
