import React, { useEffect, useRef, useState } from "react";
// Packages
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useOnClickOutside } from "../../common/function/function";
import { handleRemoveUploadedFile } from "../../redux-store/actions/albums";
import useWindowDimensions from "../../common/function/hooks/use-window-dimensions";
import { getLocal } from "../../common/function/storage";
const SideModal = (props) => {
  const ref = useRef(null);
  const _ROUTES = getLocal("routers");
  const { width } = useWindowDimensions();
  let isMobile = width <= 767;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const _OPEN_FILE_UPLOAD_MODAL = useSelector(
    (e) => e?.common?.FileUploadModal
  );
  const _MINIMIZED_FILE_UPLOAD_MODAL = useSelector(
    (e) => e?.common?.isMinimizedFileUploadModal
  );
  const _REMOVE_ALL_FILE = useSelector((e) => e.common.removeAllFile);
  const _PLAN_WARNING_MODEL_FLAG = useSelector(
    (e) => e.common.planWarningModalFlag
  );
  const _CART_DROP_DOWN_TOGGLE = useSelector(
    (e) => e.common.cartDropDownToggle
  );
  useEffect(() => {
    setTimeout(() => setOpen(true), 10);
  }, [props?.open]);

  const handleClose = () => {
    setTimeout(() => setOpen(false), 10);
    props.onClose();
  };

  if (props?.name !== "epicAudio") {
    useOnClickOutside(ref, async () => {
      if (_REMOVE_ALL_FILE.length) {
        await dispatch({ type: "CURRENT_FILE_DATA_CHANGE", payload: [] });
        await dispatch(handleRemoveUploadedFile(_REMOVE_ALL_FILE));
      }
      dispatch({ type: "OPEN_FILE_UPLOAD_MODAL", payload: false });
      props.onClose();
    });
  }

  return (
    <React.Fragment>
      <div
        className={`${"modal"}`}
        style={{ zIndex: isMobile && _CART_DROP_DOWN_TOGGLE ? "999999" : "" }}
      >
        {open && <div className="" onClick={handleClose} />}

        <div className="modal_dialog">
          <div
            className={`modal_content ${_OPEN_FILE_UPLOAD_MODAL && !_MINIMIZED_FILE_UPLOAD_MODAL
              ? "fileUpload-modal-content"
              : ""
              }`}
            style={{
              width:
                props?.icon === "icon-lock-icon"
                  ? "700px"
                  : _ROUTES === "/auth/signUp"
                    ? "660px"
                    : "",
            }}
            ref={ref}
          >

            {_ROUTES !== "/auth/signUp" ? (
              <div
                className={`modal-header fs-20 text-center  ${props?.icon === "icon-lock-icon " ||
                  props?.icon ===
                  "text-danger icon-info-icon fa-3x" ||
                  props?.icon === "icon-Upload-Your-Music-icon"
                  ? "text-center"
                  : ""
                  }`}
                id="modal-header"
              >
                {props?.close ? (
                  <span className="close" onClick={handleClose}>
                    <i className="text-light icon-close "></i>
                  </span>
                ) : null}
                {props?.icon ? (
                  <i
                    className={`text-center info-big-icon ${props?.icon} ${_PLAN_WARNING_MODEL_FLAG ? "" : ""
                      }`}
                    style={{ color: _PLAN_WARNING_MODEL_FLAG ? "#DC8A6C" : "" }}
                  ></i>
                ) : null}
                {props?.header}
                <span
                  className="modal_close_icon"
                  onClick={() => {
                    props.onClose();
                  }}
                >
                  <i
                    className="icon-close cursor-pointer font-weight-light"
                    aria-hidden="true"
                  ></i>
                </span>
              </div>
            ) : null}

            <div className="modal_body ">{props.body}</div>

            <div className="modal_footer">{props.footer}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

SideModal.propTypes = {
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
  maxWidth: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  footer: PropTypes.element,
  headerOptions: PropTypes.element,
};

export default SideModal;
