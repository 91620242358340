import React, { useEffect, useRef, useState } from "react";

// Packages
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { useOnClickOutside } from "../../common/function/function";

const SideModal = (props) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => setOpen(true), 10);
  }, [props?.open]);

  const handleClose = () => {
    setTimeout(() => setOpen(false), 10);
    dispatch({
      type: "IS_LOCK_SUBSCRIBE",
      payload: false,
    });
    props.onClose();
  };

  useOnClickOutside(ref, () => {
    if(props?.name === "epicAudio"){
      return null
    }else{
      dispatch({
        type: "IS_LOCK_SUBSCRIBE",
        payload: false,
      });
      props.onClose();
    }
  });
  const location = useRouter();
  return (
    <React.Fragment>
      <div className={`modal`}>
        <div className="modal_dialog">
          <div className={` ${
                    location?.route !== "uploadYourMusic" && props?.header !== "Share Albums"
                      ? "modal_content "
                      : props?.header === "Share Albums" 
                      ? "modal_content modal_content_share_album" 
                      : "modal_content upload_tracks_modal_content"
                  } `}
                  style={{ width: props?.icon === "icon-lock-icon" || "icon-cloud-download" ? "700px" : "" }} ref={ref}>
            <div className="modal_header">
              <div
                className={` ${
                  location?.route !== "uploadYourMusic"
                    ? "modal_header m-b-40"
                    : "upload_tracks_modal_header"
                } `}
              >
                <h4
                  className={` ${
                    location?.route !== "uploadYourMusic"
                      ? "modal_main_title"
                      : "fs-18"
                  } `}
                >
                  {location?.route !== "uploadYourMusic" && (
                    <i className={`${props.icon} header_modal_icon m-r-20`} />
                  )}
                  {props?.header ? props?.header : ""}
                </h4>
                <span
                  className="modal_close_icon"
                  onClick={() => {
                    props.onClose();
                  }}
                >
                  <i
                    className="icon-close cursor-pointer font-weight-light"
                    aria-hidden="true"
                  ></i>
                </span>
              </div>
            </div>
            <div className="modal_body">{props.body}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

SideModal.propTypes = {
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
  maxWidth: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  footer: PropTypes.element,
  headerOptions: PropTypes.element,
};

export default SideModal;
